* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 10px;
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
em,
input,
textarea,
select {
  font-family: "Lato";
}

button,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

button,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
}
