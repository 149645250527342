.estore-container {
    // padding: 4rem 1.6rem;
    font: 400 18px 'Plus Jakarta Sans';
    
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    section.community {
        padding: 5rem 8rem;
        background-color: #d4d3d3;
        .big-title {
            padding: 15px;
            h2 {
                font-size: 4rem;
                font-weight: 300;
                line-height: 1.2;
                text-align: center;
                text-transform: capitalize;
                color: #272343;
                margin-bottom: 0.8rem;
            }
        }
        .before-the-row {
            background-color: #fff;
            .row {
                display: flex;
                flex-direction: column;
                padding: .75rem 2rem;
                background-color: rgba(0, 0, 0, .03);
                border-bottom: 1px solid rgba(0, 0, 0, .125);
                .home {
                    padding: 1.5rem;
                    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
                    width: 100%;
                    button {
                        background-color: #4F0B92;
                        color: #e4e2e2;
                        padding: 1.3rem;
                        width: 100%;
                    }
                }
                .real-section {
                    display: flex;
                    flex-direction: column; 
                    gap: 2rem;
                    .welcome {
                        margin-top: 3rem;
                    }
                    .tags {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 2rem;
                        .mini-navs {
                            flex: 0 0 65%;
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            gap: 1rem;
                            .buttons {
                                color: #4F0B92;
                                background-color: #fff;
                                text-align: center;
                                padding: 1.375rem .75rem;
                                border-radius: .25rem;
                                transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                            }
                            .active-button {
                                background-color: #4F0B92;
                                color: #fff;
                            }
                        }
                        .settings {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            // font-size: 18px;
                            padding: 1.5rem;
                            background-color: #fff;
                            color: #4F0B92;
                        }
                    }

                    .split-to-two {
                        display: flex;
                        gap: 2rem;
                        .posted-questions {
                            display: flex;
                            flex-direction: column;
                            flex: 0 0 65%;
                            gap: 1rem;
                            .nav {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                p {
                                    font: 500 3rem 'lato';
                                    line-height: 1.2;
                                }
                                button {
                                    color: #fff;
                                    background-color: #4F0B92;
                                    text-align: center;
                                    font: 400 2rem 'lato';
                                    line-height: 1.5;
                                    padding: .375rem .75rem;
                                    border-radius: .25rem;
                                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                }
                            }
                            .each-content {
                                display: flex;
                                flex-direction: column;
                                gap: 1rem;
                                padding: 1.25rem;
                                background-color: #fff;
                                border: 1px solid rgba(0, 0, 0, .125);
                                border-radius: .25rem;
                                a {
                                    color: #007bff;
                                    text-decoration: none;
                                }
                                hr {
                                    margin-top: 1rem;
                                    margin-bottom: 1rem;
                                    border: 0;
                                    border-top: 1px solid rgba(0, 0, 0, .1);
                                }
                                div {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    small {
                                        font-size: 80%;
                                        font-weight: 400;
                                    }
                                }
                                .posted-answer {
                                    h4 {
                                        color: #000;
                                        text-decoration: none;
                                    }
                                    div {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        small {
                                            font-size: 80%;
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                            h4.ans {
                                margin-top: 2rem;
                            }
                            form {
                                display: flex;
                                flex-direction: column;
                                gap: 4rem;
                                div.submit-answers {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 1rem;
                                    p {
                                        font-size: 17px;
                                        span {
                                            color: red;
                                        }
                                    }
                                    input, textarea {
                                        padding: 1.25rem;
                                        background-color: #fff;
                                        border: 1px solid rgba(0, 0, 0, .125);
                                        border-radius: .25rem;
                                    }
                                }
                                button {
                                    color: #fff;
                                    background-color: #4F0B92;
                                    text-align: center;
                                    font: 400 2rem 'lato';
                                    line-height: 2.5;
                                    padding: .375rem .75rem;
                                    border-radius: 0.8rem;
                                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                }
                            }
                        }
                        .all-categories {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 3rem;
                            div.notifications {
                                .settingsDetails {
                                    .all-categories {
                                        width: 100%;
                                        display: grid;
                                        grid-template-columns: repeat(1, 1fr);   
                                        gap: 1rem;
                                        p {
                                            font-size: 17px;
                                            padding: 1.5rem;
                                            background-color: #fff;
                                            border: 1px solid rgba(0, 0, 0, .125);
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            div {
                                                flex: 0 0 15%;
                                                button {
                                                    text-align: center;
                                                    font: unset;
                                                    line-height: 2;
                                                    padding: unset;
                                                    border-radius: 1rem;
                                                    margin-top: unset;
                                                }
                                            }
                                        }
                                        p:hover {
                                            background-color: #4F0B92;
                                            color: #fff;
                                        }
                                        
                                    }
                                }
                            }
                            div {
                                &:last-child {
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    h4 {
                                        padding: 1.5rem;
                                        background-color: #fff;
                                        border: 1px solid rgba(0, 0, 0, .125);
                                        font-weight: 500;
                                        text-align: center;
                                        margin-top: .5rem;
                                    }
                                    em {
                                        padding: 1.5rem;
                                        border: 1px solid rgba(0, 0, 0, .125);
                                        font-weight: 500;
                                        text-align: center;
                                        margin-top: .5rem;
                                    }
                                    p, a {
                                        font-size: 17px;
                                        padding: 1.5rem;
                                        background-color: #fff;
                                        border: 1px solid rgba(0, 0, 0, .125);
                                    }
                                    button {
                                        color: #fff;
                                        background-color: #4F0B92;
                                        text-align: center;
                                        font: 400 2rem 'lato';
                                        line-height: 2.5;
                                        padding: .375rem .75rem;
                                        border-radius: .25rem;
                                        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                        margin-top: .5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1023px) {
    .estore-container {
        section.community {
            padding: 3rem;
        }
    }
}

@media screen and (max-width: 900px) {
    .estore-container {
        section.community {
            padding: 4rem;
            .before-the-row {
                .row {
                    .real-section {
                        .split-to-two {
                            flex-direction: column;
                            gap: 4rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .estore-container {
        section.community {
            .before-the-row {
                .row {
                    .real-section {
                        .tags {
                            flex-direction: column;
                            .mini-navs {
                                width: 100%;
                                grid-template-columns: repeat(2, 1fr);
                                .buttons {
                                    padding: 1.375rem .75rem;
                                }
                            }
                        }
                        .settingsDetails {
                            .all-categories {
                                grid-template-columns: repeat(2, 1fr);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .estore-container {
        section.community {
            padding: 1rem;
        }
    }
}