.estore-container {
  margin: 0 auto;
  background-color: white;
  padding-top: 9rem;
  margin-top: unset !important;
  overflow: hidden;

  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    color: #272343;
    margin-bottom: 0.8rem;
  }

  &>p {
  font-weight: 500 16px 'plus Jakarta sans';
    // font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #2d334a;
    margin-bottom: 3.2rem;
  }

  p {
    font-family: "plus jakarta sans";
  }

  section:first-of-type {
    padding: unset;
  }

  section.getallprods {
    display: flex;
    justify-content: center;
    align-items: center;
    // opacity: 0.5;
    position: relative;

    .allprodsImage {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 60vh;
      width: 100%;

      img {
        max-width: 30%;
        height: auto;
      }
    }

    .describeProds {
      position: absolute;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%; // 100vw is appropriate but then, I'm getting the scrolling stufff
      height: 100%;
      background-color: #2D334A;
      opacity: 0.9;
      gap: 5rem;

      .b4Title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .titleD {
          gap: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          h3 {
            color: #fff;
            font-size: 60px;
            line-height: 50.4px;
            font-weight: 400;
          }

          .pTitle {
            font-family: 'Plus Jakatra Sans';
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: white;
            height: 75px;
            width: 545px;
          }

        }

        // .searchIt {
        //   background: #fff;
        //   border-radius: 16px;
        //   border: 1px solid #eaeaea;
        //   display: flex;
        //   align-items: center;

        //   justify-content: space-between;

        //   form {
        //     display: flex;
        //     align-items: center;
        //     width: 100%;

        //     div {
        //       border: solid #eaeaea;
        //       border-width: 0 1px 0 0;
        //       padding: 0 8px;
        //       display: flex;
        //       gap: 8px;
        //       align-items: flex-end;
        //       justify-content: center;
        //       flex: 0 0 42%;

        //       select {
        //         width: 100%;
        //         color: #2d334a;
          
        //       }

        //       option {
        //         color: var(--text-3, #2d334a);
        //         text-align: left;
        //         font: 600 16px PlusJakartaSans-Medium, sans-serif;
        //       }

        //       p {
        //         text-align: center;
        //         font-size: 2rem;
        //       }
        //     }

        //     input {
        //       text-align: left;
        //       font: 500 16px PlusJakartaSans-Medium, sans-serif;
        //       color: var(--text-3, #a0a2a7);
        //       width: 375px;
        //     }

        //     .searchbtn {
        //       margin-right: 1rem !important; //or use unset
        //       background: var(--primary, #4F0B92);
        //       border-radius: 8px;
        //       flex-shrink: 0;
        //       width: 56px;
        //       height: 56px;
        //     }
        //   }
        // }
        .searchIt {
          background: #fff;
          border-radius: 16px;
          border: 1px solid #eaeaea;
          display: flex;
          align-items: center;
          width: unset;
          height: 70px;
          justify-content: space-between;
          form {  
            display: flex;
            align-items: center;
            width: 100%;
            div {
              border: solid #eaeaea;
              border-width: 0 1px 0 0;
              padding: 0 10px;
              display: flex;
              gap: 8px;
              align-items: flex-end;
              justify-content: center;
              flex: 0 0 42%;
              select {
                width: 100%;
                color: #2d334a;
              }
              option {
                color: var(--text-3, #2d334a);
                text-align: left;
                font: 550 15px PlusJakartaSans-Medium,sans-serif;
              } p {
                text-align: center;
                font-size: 2rem;
              }
            } input {
              text-align: left;
              font: 500 16px PlusJakartaSans-Medium,sans-serif;
              color: var(--text-3, #a0a2a7);
              width: 375px;
            } .searchbtn {
              background: var(--primary, #4F0B92);
              border-radius: 8px;
              flex-shrink: 0;
              width: 56px;
              height: 56px;
            }
          }
        }
      }

      .buttons {
        display: flex;
        gap: 22px;
        padding-top: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
        width: 100%;

        button,
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background: hsla(0, 0%, 100%, .1);
          border-radius: 24px;
          padding: 8px;
          gap: 8px;
          width: 22rem;
          height: 64px;

          p {
            color: #fff;
            text-align: left;
            font: 600 20px PlusJakartaSans-SemiBold, sans-serif;

          }
        }
      }
    }
  }

  section.topdeals {
    padding: 5rem 5.6rem;
    // max-width: 1280px;
    // margin: 0 auto;
    .items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;


      .eachItem {
        cursor: unset;
        height: 100%;

        img {
          width: 100%;
          height: 20rem;
          border-radius: 12px;
          cursor: pointer;
        }

        div.banner {
          // background-image: url("../../assets/updatedAshopree/single_product_banner.png");
          // background-repeat: round;
          background-color: #4F0B92;
          position: absolute;
          margin-top: -5.5rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 24px;
          left: 0;
          border-radius: 12px;

          div {
            p {
              &:first-child {
                font: 200 20px "Bella Script";
                line-height: 20px;
                color: #fff;
              }

              &:last-child {
                font: 800 13px "plus jakarta sans";
                color: #fff;
                text-transform: uppercase;
              }
            }
          }

          p {
            font: 500 15px "plus jakarta sans";
            color: #fff;
            span {
              color: #c6c6c6;
              font-size: 12px;
              margin-left: .5rem;
              text-decoration: line-through;
            }
          }
        }

        .imgdescription {
          margin: 4% auto;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          .nameofitem {
            font-weight: 700;
            font-size: 20px;
            line-height: 25.2px;
            cursor: pointer;
          }

          .priceofitem {
            font-weight: 500;
            font-size: 18px;
          }

          .initialprice {
            color: #c6c6c6;
            flex: 0 0 40%;
            font-size: 14.5px;
            font-weight: 500;
            text-decoration: line-through;
          }

          div.first {
            div {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                svg {
                  color: #FF6D01;
                  margin-right: 3px;
                }
              }

              button {
                svg {
                  width: 24px;
                  height: 24px;
                  color: #4F0B92;
                }
              }
            }
          }

          div.second {
            display: flex;
            flex-direction: column;

            .both {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p.items-left {
                color: #FC9B67 !important;
                font: 400 16px 'plus jarkata sans';
                line-height: 23px;

              }

              p {
                color: #2D334A;

                svg {
                  color: #FF6D01;
                }
              }
            }

            div.ratings {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1rem;

              span {
                display: flex;
                gap: 0.2rem;
                img {
                  width: 2rem;
                  height: 2rem;
                }
              }
              p {
                text-decoration: unset;
              }
            }
          }
        }
      }
    }

    .dealtitle {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 40px;
      color: #2D334A;
      margin-bottom: 10px;
    }
  }

  section.topproducts {
    padding: 5rem 5.6rem;
    // max-width: 1280px;
    // margin: 0 auto;
    .items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;

      .eachItem {
        cursor: unset;
        height: 100%;

        img {
          width: 100%;
          height: 20rem;
          border-radius: 12px;
          cursor: pointer;
        }

        div.banner {
          background-color: #4F0B92;
          position: absolute;
          margin-top: -5.5rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 24px;
          left: 0;
          border-radius: 12px;

          div {
            p {
              &:first-child {
                font: 200 20px "Bella Script";
                line-height: 20px;
                color: #fff;
              }

              &:last-child {
                font: 800 13px "plus jakarta sans";
                color: #fff;
                text-transform: uppercase;
              }
            }
          }

          p {
            font: 500 15px "plus jakarta sans";
            color: #fff;
            span {
              color: #c6c6c6;
              font-size: 12px;
              margin-left: .5rem;
              text-decoration: line-through;
            }
          }
        }

        .imgdescription {
          margin: 4% auto;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          .nameofitem {
            font-weight: 700;
            font-size: 20px;
            line-height: 25.2px;
            cursor: pointer;
          }

          .priceofitem {
            font-weight: 500;
            font-size: 18px;
          }

          .initialprice {
            color: #c6c6c6;
            flex: 0 0 40%;
            font-size: 14.5px;
            font-weight: 500;
            text-decoration: line-through;
          }

          div.first {
            div {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                svg {
                  color: #FF6D01;
                  margin-right: 3px;
                }
              }

              button {
                svg {
                  width: 24px;
                  height: 24px;
                  color: #4F0B92;
                }
              }
            }
          }

          div.second {
            display: flex;
            flex-direction: column;

            .both {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p.items-left {
                color: #FC9B67 !important;
                font: 400 16px 'plus jarkata sans';
                line-height: 23px;

              }

              p {
                color: #2D334A;

                svg {
                  color: #FF6D01;
                }
              }
            }

            div.ratings {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1rem;

              span {
                display: flex;
                gap: 0.2rem;
                img {
                  width: 2rem;
                  height: 2rem;
                }
              }
              p {
                text-decoration: unset;
              }
            }
          }
        }
      }
    }

    .dealtitle {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 40px;
      color: #2D334A;
      margin-bottom: 10px;
    }
  }


  section.registered {
    padding: 5rem 5.6rem;
    background-color: #2D334A;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    h3 {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 40px;
      color: #fff;
      text-align: center;
      margin-bottom: 2rem;
    }

    .otherImages {
      .firstSection {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.5rem;
        margin-top: 1rem;
        grid-column: 2 / span 3;

        div {
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;

          img {
            border-radius: 1rem;
            height: 13.5rem;
            width: 30rem;
            // filter: blur(1px);
          }

          p {
            font-weight: 700;
            font-size: 20px;
            font-family: "Plus Jakarta Sans";
            color: #fff;
            text-align: center;
            position: absolute;
          }
        }
      }
    }

    button {
      text-align: center;
      padding: 1.5rem 7rem;
      font-size: 20px;
      font-weight: 500;
      font-family: "Plus Jakarta Sans";
      color: #4F0B92;
      background-color: #fff;
      border: 2px solid #4F0B92;
      border-radius: 1rem;
    }
  }

  section.topcategories {
    padding: 5rem 5.6rem;
    background-color: #f5f5f5;

    h3 {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 40px;
      color: #2D334A;
      text-align: center;
      margin-bottom: 2rem;
    }

    .imageitems {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 2rem;
      padding: 2rem;
      row-gap: 2rem;

      .imgdescribtion {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 20rem;
        background-color: #fff;
        border-radius: 1.5rem;
        gap: 0.5rem;

        img {
          width: 10rem;
          height: 10rem;
        }

        p {
          font-weight: 500;
          font-size: 20px;
          color: #2D334A;
          padding: 0.5rem 2rem;
        }
      }

      .imgdescribtion:hover {
        transform: scale(0.9);
        transition: transform 0.5s ease-in, opacity 0.5s ease-in;
        cursor: pointer;
      }
    }

    .thebutton {
      padding: 2rem;
      display: flex;
      margin-top: 3rem;
      justify-content: center;

      button {
        text-align: center;
        padding: 1.5rem 7rem;
        font-size: 20px;
        font-weight: 500;
        font-family: "Plus Jakarta Sans";
        color: #2D334A;
        border: 2px solid #4F0B92;
        border-radius: 1rem;
      }
    }
  }

}


// @media screen and (max-width: 1500px) {
//   .estore-container {
//     margin-top: 5% !important;
//   }
// }

@media screen and (min-width: 1200px) {
  .estore-container {
    padding-top: 7rem;

    &>p {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
    }

    section.getallprods {
      // max-width: 1200px;
      margin: 0 auto;
      // display: flex;
      // justify-content: space-between;
    }


  }
}


@media screen and (max-width: 900px) {
  .estore-container {
    padding-top: 2rem;

    section.getallprods {
      margin-top: 20px;
      div.describeProds {
        .searchIt {
          height: 65px;
          form {
            .searchbtn {
              width: 40px;
              height: 40px;
              svg {
                margin-left: 1.25rem !important;
              }
            }
          }
        }
      }
    }

    p.initialprice {
      flex: unset !important;
    }


    section.topdeals {
      margin-left: auto;
      margin-right: auto;

      .items {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    section.topproducts {
      margin-left: auto;
      margin-right: auto;

      .items {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    section.registered {
      .otherImages {
        overflow: scroll;

        .firstSection {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    section.topcategories {
      .imageitems {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}


@media screen and (max-width: 800px) {
  .estore-container {
    section.getallprods {
      margin-top: 20px;
    }

    section.topdeals {
      margin-left: auto;
      margin-right: auto;
      padding: 2rem 1.5rem;

      .dealtitle {
        font-size: 35px;
      }

      .items {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    section.topproducts {
      margin-left: auto;
      margin-right: auto;
      padding: 2rem 1.5rem;

      .producttitle {
        .realtitle {
          font-size: 35px;
        }
      }

      .items {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    section.registered {
      padding: 2rem 1.5rem;

      h3 {
        font-size: 35px;
      }
    }

  }
}

@media screen and (max-width: 700px) {
  .estore-container {
    section.getallprods {
      margin-top: 30px;
      height: 40vh;

      div.describeProds {
        gap: 1rem;
        padding: 2rem 2rem;

        .b4Title {
          width: 100%;

          .titleD {
            gap: 1rem;

            h3 {
              font-size: 20px;
              line-height: 35px;
              font-weight: 800;
            }

            .pTitle {
              font-size: 15px;
              font-weight: 100;
              width: 300px;
              margin-bottom: -60px;
            }
          }

          .searchIt {
            height: 50px;
            width: 80%;
            form {
              padding: 1rem;
              gap: 1rem;
              div {
                padding: unset;
                flex: 0 0 35%;
                font-size: 16px;
              }
              input {
                font-size: 14px;
              }
              .searchbtn {
                width: 40px;
                height: 40px;
                svg {
                  margin-left: 0.8rem !important;
                }
              }
            }
          }
        }
      }
    }

    section.topdeals {
      margin-left: auto;
      margin-right: auto;

      .items {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;

        .eachItem {
          div {
            .imgdescription {
              .nameofitem {
                font-size: 20px;
              }

              .priceofitem {
                font-size: 16px;
              }

              .initialprice {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    section.topproducts {
      margin-left: auto;
      margin-right: auto;

      .items {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;

        .eachItem {
          div {
            .imgdescription {
              .nameofitem {
                font-size: 20px;
              }

              .priceofitem {
                font-size: 16px;
              }

              .initialprice {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    section.topcategories {
      .imageitems {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    section.registered {
      .otherImages {
        .firstSection {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

  }
}

@media screen and (max-width: 600px) {
  .estore-container {
    padding-top: 0rem;

    section.getallprods {

      div.describeProds {
        .b4Title {
          .searchIt {
            width: 90%;
            form {
              .searchbtn {
                width: 30px;
                height: 30px;
                svg {
                  width: 15px;
                }
              }
            }
          }
        }
      }
    }

    section.topdeals {
      padding: 1rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .estore-container {
    section.getallprods {
      margin-top: 20px;

        .allprodsImage {
          img {
            max-width: 25%;
            height: auto;
          }
        }

        div.describeProds {
          gap: 1rem;
          padding: 2rem;
          .b4Title {
            width: 100%;
            justify-content: space-evenly;
            gap: 2rem;
            height: 100%;
            .title {
              width: 100%;
              h2 {
                font-size: 23px !important;
                line-height: unset !important;
              }
              p {
                font-size: 18px;
                line-height: 25px;
                text-align: left;
                width: 100%;
                height: 100%;
              }
            }
            .titleD {
              padding: 0rem;
              .pTitle {
                margin-bottom: -40px;
              }
            }
            .searchIt {
              height: 50px;
              width: 100%;
              form {
                div {
                  padding: unset;
                  flex: 0 0 35%;
                  select {
                    font-size: 14px;
                    option {
                      font-size: 14px;
                    }
                  }
                }
                input {
                  font-size: 13px;
                }
                .searchbtn {
                  svg {
                    margin-left: 0.8rem !important;
                  }
                }
              }
            }
          }
        }
    }

    section.topdeals {
      margin-left: auto;
      margin-right: auto;
      padding: 2.5rem;

      .dealtitle {
        font-size: 32px;
      }

      .items {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 1rem;
      }
    }

    section.topproducts {
      margin-left: auto;
      margin-right: auto;
      padding: 2.5rem;

      .producttitle {
        align-items: center;

        .realtitle {
          font-size: 32px;
        }
      }

      .items {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        width: 100%;

        a {
          .eachItem {
            .prodImage {
              width: 100%;
            }
          }
        }
      }
    }

    section.topcategories {
      padding: 2.5rem;

      .imageitems {
        padding: 0rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    section.registered {
      padding: 2.5rem;

      .otherImages {
        .firstSection {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      button {
        padding: 1.5rem 5rem;
      }
    }

  }
}

// estore.homepage.scss

.fade-in {
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slide-in {
  opacity: 0;
  transform: translateY(70px);
  /* Initial position off-screen */
  transition: opacity 5s ease, transform 5s ease;
}

.slide-in.active {
  opacity: 1;
  transform: translateY(0);
  /* Slide into view */
}