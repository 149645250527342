.estore-container {
    // padding: 4rem 1.6rem;
    font: 500 20px 'Plus Jakarta Sans';
    
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }

    section.referralpartner {
        display: flex;
        div:first-child {
            padding: 5rem 5.6rem;
            flex: 0 0 50%;
            img {
                height: 565px;
                border-radius: 8px;
            }
        }
        div:last-child {
            padding: 5rem 5.6rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 60px;
            h2 {
                font: 600 30px 'plus jakarta sans';
                line-height: 35px;
                margin: unset;
            }
            p {
                font: 500 20px 'lato';
                line-height: 30px;
            }
            div.navBtns {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 30px;
                padding: 1.5rem;
                button {
                    font: 400 20px 'lato';
                    background-color: #4F0B92;
                    border-radius: 15px;
                    color: #fff;
                    text-align: center;
                    width: 100%;
                    height: 70px;
                    padding: 1rem;
                }
            }
        }
    }
  
    section.theNextToSee, section.theForm, .loginScreen {
        display: none;
    } 
    .active {
        padding: 5rem 25rem;
        display: flex !important;
        flex-direction: column;
        gap: 3rem;
        align-items: center;
        .login-choice {
            width: 100%;
            h4 {
                font: 600 22px 'lato';
                line-height: 35px;
                text-align: left;
            }
            a {
                font: 600 22px 'lato';
                line-height: 53px;
                color: #007bff;
            }
        }
        h4 {
            font: 600 25px 'lato';
            line-height: 35px;
        }
        ol {
            list-style: auto;
            padding: 0 2rem;
            li {
                font: 400 20px 'lato';
                line-height: 30px;
            }
        }
        p {
            font: 800 22px 'lato';
            line-height: 35px;
            text-align: center;
        }
        button {
            font: 400 25px 'lato';
            background-color: #4F0B92;
            border-radius: 15px;
            color: #fff;
            width: 425px;
            height: 90px;
        }

    }

    .activeForm {
        padding: 5rem 23rem;
        display: flex !important;
        flex-direction: column;
        gap: 3rem;
        align-items: center;
        h4 {
            font: 600 30px 'lato';
            line-height: 53px;
        }
        #loginForm {
            .allForm {
                grid-template-columns: repeat(1, 1fr);
                width: 50%;
            }
        }
        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            gap: 5rem;
            .allForm {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
                gap: 3rem;
                align-items: center;
                justify-content: center;
                .largeDiv {
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    p {
                        font: 400 20px 'lato';
                        line-height: 20px;
                        width: 100%;
                        span {
                            color: red;
                        }
                    }
                    .bigDiv {
                        display: flex;
                        border: 2px solid #4F0B92;
                        border-radius: 5px;
                        align-items: center;
                        gap: 1rem;
                        width: 100%;
                        span, img {
                            background-color: #e9ecef;
                            padding: .375rem .75rem;
                        }
                        input, select {
                            font-size: 2rem;
                            width: 100%;
                            margin-right: 1rem;
                        }
                    } 
                } 
            } 
            button {
                font: 400 25px 'lato';
                background-color: #4F0B92;
                border-radius: 15px;
                color: #fff;
                width: 50%;
                height: 70px;
            } 
        }
        input, select, textarea {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }
        input:focus, select:focus, textarea:focus {
            border: 1px solid #007bff;
            box-shadow: 0 0 0 .3rem rgba(0,123,255,.25);
        }
    }
}

@media screen and (max-width: 1200px) {
    .estore-container { 
        section.referralpartner  {        
            div:first-child {
                padding: 4rem;
                flex: 0 0 50%;
                img {
                    height: 480px;
                    border-radius: 8px;
                }
            }
        }
        
    }
}

@media screen and (max-width: 1100px) {
    .estore-container { 
        section.referralpartner  {
        
            div:first-child {
                padding: 4rem;
                flex: 0 0 45%;
                img {
                    height: 480px;
                    border-radius: 8px;
                }
            }
            div:last-child {
                padding: 4rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 50px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .estore-container { 
        section.referralpartner  {        
            div:first-child {
                padding: 4rem;
                flex: 0 0 45%;
                img {
                    height: 405px;
                    border-radius: 8px;
                }
            }
            div:last-child {
                padding: 4rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 25px;
            }
        }
        .active, .activeForm {
            padding: 5rem;
        }
    }
}

@media screen and (max-width: 950px) {
    .estore-container { 
        section.referralpartner  {
        
            div:first-child, div:last-child {
                padding: 2rem;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .estore-container { 
        section.referralpartner  {
            flex-direction: column;
        }
        .active {
            button {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .estore-container { 
        .activeForm {
            form {
                .allForm {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .estore-container { 
        section.referralpartner  {
            div:first-child {
                img {
                    height: 300px;
                }
            }
            div:last-child {
                div.navBtns {
                    flex-direction: column;
                }
            }
        }
    }
}


