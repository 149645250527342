div.left-side {
    flex: 0 0 345px;
    padding: 0px 35px;
    ul.side-menu {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .active {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 24px;
            height: 64px;
            padding: 15px 20px 15px 20px;
            border-radius: 15px;
            background-color: #4F0B92;
            p {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;
                color: #fff;
                svg {
                    margin-top: 0.3rem;
                }
            }
        }
        a {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 24px;
            height: 64px;
            padding: 15px 20px 15px 20px;
            border-radius: 15px;
            background-color: #fff;
            p {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;
                color: #737791;
            }
        }
        a:hover {
            background-color: #4F0B92;
            p {
                color: #fff;
            }
        }
    }
}


@media screen and (max-width: 900px) {
    div.left-side {
        padding: 10px;
        ul.side-menu { 
            gap: 3rem;
            .active {
                height: 45px;
            }
            a {
                padding: 2px;
                height: 30px;
                p {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #272343;
                    text-decoration: none;
                    img {
                        width: auto;
                        height: 1.4em;
                    }
                }
            }
        }
    }
}