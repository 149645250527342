.estore-container {
    // padding: 4rem 1.6rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    span {
        color: #A0A2A7;
    }

    section.successfulorder {
        padding: 5rem 5.6rem;
        .shouldnotbeeditable {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5rem;
            .themsg {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.3rem;
                h2 {
                    font: 500 40px 'Plus jakarta sans';
                    line-height: 50.4px;
                    color: #2D334A;
                } p {
                    font: 500 20px 'Plus jakarta sans';
                    line-height: 25px;
                    color: #5B5D64;
                }
            }
            .thedetails {
                display: flex;
                gap: 1.5rem;
                flex-direction: column;
                padding: 2.5rem;
                border: 2px solid #EAEAEA;
                border-radius: 1.2rem;
                margin-bottom: 1rem;
                width: 70%;
                h4 {
                    text-transform: uppercase;
                    font-size: 14px;
                    color: #A0A2A7;
                }
                .descrip {
                    // padding: 1rem;
                    p {
                        font: 500 16px 'Plus jakarta Sans';
                        line-height: 20.16px;
                        color: #5B5D64;
                        margin-bottom: 1rem;
                    }
                    .detailed {
                        font: 500 20px 'Plus Jakarta Sans';
                        line-height: 25px;
                        color: #2D334A;
                    } 
                    .theorder {
                        display: flex;
                        gap: 2rem;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 1.5rem;
                        img {
                            width: 15rem;
                            height: 11rem;
                            border-radius: 2rem;
                        }
                        > p {
                            margin-bottom: 4rem;
                            font: 600 24px 'Plus jakarta sans';
                            color: #2D334A;
                        }
                        p:not(:last-child) {
                            flex: 0 0 50%;
                        }
                    }
                }
                .grped {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    margin-bottom: 2rem;
                    > div { 
                        display: flex; 
                        justify-content: space-between;
                        > p:first-child {
                            font: 500 20px 'Plus Jakarta Sans';
                            line-height: 25.2px;
                            color: #A0A2A7;
                        }
                        > p:last-child {
                            font: 600 20px 'Plus Jakarta Sans';
                            line-height: 25.2px;
                            color: #2D334A;
                        }
                    }
                }
                button {
                    background-color: #4F0B92;
                    border-radius: 1rem;
                    padding: 2rem;
                    margin-bottom: 2rem;
                    p {
                        font: 500 20px 'Lato';
                        color: #fff;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 900px) {
    .estore-container {
        section.successfulorder {
            padding: 2rem;
            .shouldnotbeeditable {
                .thedetails {
                    width: 100%;
                    .descrip {
                        .theorder {
                            p:not(:last-child) {
                                flex: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}

