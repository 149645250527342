.estore-container {
    // padding: 4rem 1.6rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    span {
        color: #A0A2A7;
    }

    section.messages {
        padding: 5rem 6.5rem;
        display: flex;
        height: 90vh;
        .firstside {
            flex: 0 0 30%;
            display: flex;
            gap: 1rem;
            flex-direction: column;
            h3 {
                font: 600 25px 'Plus jakarta Sans';
                line-height: 31px;
                color: #4F0B92;
                margin-left: 2rem;
                padding: 0.5rem;
            } hr {
                border: 1px solid #f5f5f5;
            }
            .asidetitle {
                display: flex;
                gap: 0.5rem;
                padding: 1rem;
                cursor: pointer;
                img {
                    flex: 0 0 30%;
                    width: 2rem;
                    height: 10rem;
                    border-radius: 50%;
                }
                .inner {
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;
                    width: 100%;
                    .title {
                        display: flex;
                        justify-content: space-between;
                        font: 500 16px 'Plus Jakarta Sans';
                        line-height: 20px;
                        color: #5B5D64;
                    }
                    p:not(:last-child) {
                        font: 600 16px "Plus Jarkata Sans";
                        line-height: 20px;
                        color: #2D334A;
                    } p:last-child {
                        font: 500 15px 'Plus jakarta sans';
                        line-height: 17px;
                        color: #5B5D64;
                    }
                }
            }
        }
        .secondside {
            width: 100%;
            background-color: #f5f5f5;
            border-radius: 10px;
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 19rem auto;
                cursor: pointer;
            }
        }
        .realmsgs {
            width: 100%;
            background-color: #f5f5f5;
            border-radius: 10px;
            flex-direction: column;
            gap: 3rem;
            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 2rem;
                background-color: #fff;
                div h4 {
                    font: 500 20px 'plus jakarta sans';
                    line-height: 25px;
                    color: #5B5D64;
                    margin: auto 10px;
                } div img {
                    // flex: 0 0 30%;
                    width: 6rem;
                    height: 6rem;
                    border-radius: 50%;
                }
                p {
                  font: 500 16px 'plus jakarta sans';
                  line-height: 22px;
                  color: #5B5D64;
                }
            }
            .thedate {
                font: 500 16px "Plus Jakarta Sans";
                text-align: center;
                margin-top: 1rem;
            }
            div:not(.title):nth-last-child(3)  {
                display: flex;
                justify-content: flex-end;
                padding: 0 3rem;
                p {
                    width: 30%;
                    font: 500 15px 'plus jakarta sans';
                    color: #2D334A;
                    background: #FFDA00;
                    padding: 1.5rem;
                    border-radius: 2rem;
                }
            }
            div:not(.title):nth-last-child(2) {
                display: flex;
                padding: 0 3rem;
                p {
                    width: 30%;
                    font: 500 15px 'plus jakarta sans';
                    color: #2D334A;
                    background: #fff;
                    padding: 1.5rem;
                    border-radius: 2rem;
                }
            }
            section {
                // margin-top: 5rem;
                .compose {
                    input {
                        width: 100%;
                        height: 100%;
                        font: 500 20px 'Satoshi';
                        color: #828282;
                        flex: 0 0 50%;
                    }
                    padding: 1rem 2rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 0.5rem;
                    border: 1px solid #f5f5f5;
                    background: #fff;
                    height: 82px;
                    div {
                        display: flex;
                        gap: 3rem;
                        margin-right: 2rem;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 900px) {
    .estore-container {
        section.messages {
            flex-direction: column;
        }
    }
}


@media screen and (max-width: 600px) {
    .estore-container {
        section.maincontent {
            .relevantstuffs {
                .details {
                    .realdeals {
                        div {
                            display: flex;
                            justify-content: space-between;
                            > div:first-child {
                                flex-direction: column;
                                flex: 0 0 45%;
                                p {
                                    font: 600 20px 'Plus Jakarta Sans';
                                    color: #2D334A;
                                    margin-top: 2rem;
                                }
                                img {
                                    width: 150px;
                                    height: 123px;
                                    border-radius: 10px;
                                }
                            }
                            > div:not(:last-child) {
                                div {
                                    p {
                                      &:first-child, &:last-child {
                                        border: 1px solid #E7AA07;
                                        height: 3rem;
                                        width: 3rem;
                                        text-align: center;
                                        border-radius: 5px;
                                        cursor: pointer;
                                      }
                                    }
                                }
                            }
                            .price {
                                font: 600 25px 'Plus Jakarta Sans';
                                line-height: 30.24px;
                                color: #2D334A;
                                margin-top: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

