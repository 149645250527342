.estore-container {
  margin: 0 auto;
  background-color: white;
  // padding: 1rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    .hidden {
      display: none;
    }
    em.error {
      font-style: normal;
      font-size: 1.6rem;
      color: rgba(230, 34, 34, 0.808);
      font-weight: 600;
      text-align: center;
    }

    section.get-started {
      display: flex;
      flex-direction: column;
      align-items: center;
      div.main {
        border: 1px solid #f8f9fa;
        border-radius: 15px;
        box-shadow: 10px 50px 50px 50px #eee;
        padding: 30px;
        width: 80%;
        display: flex;
        gap: 7rem;
        align-items: center;
        justify-content: space-between;
        div.header {
          display: flex;
          flex-direction: column;
          gap: 7rem;
          div.ashopree {
            padding: 0% 20%;
          }
          div.body {
            display: flex;
            flex-direction: column;
            gap: 14rem;
            div.externals {
              display: flex;
              flex-direction: column;
              gap: 2rem;
              button {
                display: flex;
                gap: 1rem;
                align-items: center;
                justify-content: center;
                padding: 8px;
                background-color: #423F5A;
                color: #fff;
                font: 700 30px 'plus jarkata sans';
                line-height: 60px;
                border-radius: 10px;
                img {
                  width: 40px;
                  border-radius: 50%;
                  height: 40px;
                }
              }
            }
          } 
        }
        form.emailSignIn.loginOption, form.paysprintSignin.loginOption {
          flex: 0 0 50%;
          display: flex;
          flex-direction: column;
          width: 544px;
          gap: 48px;
          div.requirements {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            p {
              hr {
                border-color: hsla(0,0%,100%,.12);
                display: block;
                flex: 1 1;
                border: solid;
                border-width: thin 0 0;
              }
              span.alt {
                padding: 16px 8px;
                font-size: 22px;
                position: relative;
                top: -1.5rem;
                right: -33%;
                background-color: #fff;
                font-weight: 800;
              }
            }
            div.info-session {
              padding: 5px 20px;
              background: #e6f4ff;
              border-radius: 8px;
              border: 1px solid #91caff;
              display: flex;
              align-items: center;
              gap: 1rem;
              svg {
                color: #91caff;
              }
            }
            div {
              p {
                font: 500 14px 'plus jakarta sans';
                line-height: 30px;
                span {
                  font: 500 14px 'plus jarkata sans';
                  color: red;
                }
              }
              input, select, textarea {
                padding: .375rem .75rem;
                font: 600 16px 'lato';
                width: 100%;
                line-height: 19px;
                height: 48px;
                color: #423F5A;
                background-color: #fff;
                background-clip: padding-box;
                border: 2px solid #d2d2d2;
                border-radius: 8px;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
              }
              input:focus, select:focus, textarea:focus {
                  border: 1px solid #007bff;
                  box-shadow: 0 0 0 .3rem rgba(0,123,255,.25);
              }
            }
          }
          div.other-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            button {
              width: 100%;
              padding: 8px;
              background-color: #423F5A;
              color: #fff;
              font: 700 16px 'plus jarkata sans';
              line-height: 30px;
              border-radius: 10px;
            }
            p  {
              font: 500 18px 'lato';
              line-height: 26px;
              color: #8B8B8B;
              a {
                font: 700 18px 'lato';
                color: #423F5A;
              }
            }
          }
        }
        .paysprintSignin, .emailSignIn {
          display: none;
        }
      }
    }
}
  
@media screen and (max-width: 1100px) {
  .estore-container {
    section.get-started {
      div.main {
        width: 90%;
        gap: 3rem;
        margin-bottom: 5rem;
        margin-top: 2rem;
      }
    }
  }
}
  
@media screen and (max-width: 900px) {
  .estore-container {
    section.get-started {
      div.main {
        flex-direction: column;
        gap: 5rem;
        margin-top: 3rem;
        div.header {
          div.ashopree {
            padding: 0% 15%;
          }
        }
      }
    }
  }
}
  
@media screen and (max-width: 600px) {
  .estore-container {
    section.get-started {
      div.main {
        padding: 2%;
        width: 95%;
        div.header {
          gap: 3rem;
          div.ashopree {
            padding: 0% 25%;
          }
          div.body {
            gap: 4rem;
            div.head {
              h1 {
                font-size: 23px;
                text-align: left;
                margin: unset;
              }
            }
            div.externals {
              button {
                gap: 1rem;
                padding: 8px;
                font: 700 20px 'plus jarkata sans';
                line-height: 30px;
                border-radius: 10px;
                img {
                  width: 40px;
                  border-radius: 50%;
                  height: 40px;
                }
              }
            }
          } 
        }
        form.emailSignIn.loginOption, form.paysprintSignin.loginOption {
          width: 100%;
          div.requirements {
            p span.alt {
              right: -25%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1218px) {
  .estore-container {
  section.get-started {
      padding: 6rem 0rem 5.6rem 0rem !important;
    }
  }
}