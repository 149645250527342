.estore-container {
    // padding: 4rem 1.6rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    span {
        color: #A0A2A7;
    }

    section.maincontent {
        padding: 5rem 5.6rem;
        display: flex;
        gap: 2rem;
        .relevantstuffs {
            display: flex;
            flex-direction: column;
            flex: 0 0 70%;
            gap: 2rem;
            .titlesandall {
                display: flex;
                justify-content: space-between;
            }
            .sortby {
                background-color: rgba(0, 0, 0, 0.05);
                padding: 2rem;
                display: flex;
                flex-direction: column;
                gap: 1.2rem;
                h5 {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
                select {
                    width: 100%;
                    height: calc(1.44em + 1.2rem + 2px);
                    padding: 0.75rem 1.5625rem;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: 8px;
                    font-size: 1.3rem;
                }
            }
            .details {
                display: flex;
                flex-direction: column;
                padding: 2rem 2rem;
                border: 1px solid #EAEAEA;
                border-radius: 15px;
                width: 100%;
                gap: 1.5rem;
                .detailstitle {
                    display: flex;
                    justify-content: space-between;
                    gap: 2rem;
                    padding: 0rem 1rem;
                    p {
                        &:first-child {
                            flex: 0 0 15%;
                        }
                        font: 600 15px 'Plus Jakarta Sans';
                        line-height: 18px;
                        color: #A0A2A7;
                        text-transform: uppercase;
                    }
                }
                .realdeals {
                    div {
                        display: flex;
                        justify-content: space-between;
                        > div:first-child {
                            flex: 0 0 45%;
                            p {
                                font: 600 20px 'Plus Jakarta Sans';
                                color: #2D334A;
                                margin-top: 2rem;
                            }
                            img {
                                width: 150px;
                                height: 123px;
                                border-radius: 10px;
                            }
                        }
                        > div:not(:last-child) {
                            div {
                                p {
                                  &:first-child, &:last-child {
                                    border: 1px solid #4F0B92;
                                    height: 3rem;
                                    width: 3rem;
                                    text-align: center;
                                    border-radius: 5px;
                                    cursor: pointer;
                                  }
                                }
                            }
                        }
                        .price {
                            font: 600 25px 'Plus Jakarta Sans';
                            line-height: 30.24px;
                            color: #2D334A;
                            margin-top: 2rem;
                        }
                    }
                }
                .table-container {
                    width: 100%;
                    overflow-x: auto;
                    .custom-table {
                        width: 100%;
                        border-collapse: collapse;
                            th {
                                font: 600 15px 'Plus Jakarta Sans';
                                line-height: 18px;
                                color: #A0A2A7;
                                text-transform: uppercase;
                            }
                    }
                    .custom-table th, .custom-table td {
                        padding: 5px;
                        border: 1px solid #ddd;
                        font-size: 18.5px;
                        div.action {
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                            button {
                                justify-content: center;
                                &:first-child {
                                    padding: 0.5rem 1rem;
                                    display: flex;
                                    gap: 1rem;
                                    align-items: center;
                                    background: #4F0B92;
                                    color: #fff;
                                    border-radius: 1rem;  
                                }                          
                                &:last-child {
                                    padding: 0.5rem 1rem;
                                    display: flex;
                                    gap: 1rem;
                                    align-items: center;
                                    background: rgb(238, 52, 52);
                                    color: #fff;
                                    border-radius: 1rem;  
                                }                          
                            }
                        }
                    }
                    .custom-table tbody tr:nth-child(even) {
                        background-color: #f2f2f2;
                    }

                }
            }
        }
        .sideconts {
            background-color: #f5f5f5;
            height: 100%;
            width: 100%;
            gap: 2rem;
            padding: 2.5rem;
            border-radius: 2.5rem;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            > p:first-child {
                color: #A0A2A7;
                font: 600 15px 'Plus Jakarta Sans';
                line-height: 18px;
                text-transform: uppercase;
            }
            .grped {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                > div { 
                    display: flex; 
                    justify-content: space-between;
                    > p:first-child {
                        font: 500 20px 'Plus Jakarta Sans';
                        line-height: 25.2px;
                        color: #A0A2A7;
                    }
                    > p:last-child {
                        font: 600 20px 'Plus Jakarta Sans';
                        line-height: 25.2px;
                        color: #2D334A;
                    }
                }
            }
            button {
                background-color: #4F0B92;
                border-radius: 1rem;
                padding: 2rem;
                margin-bottom: 2rem;
                p {
                    font: 500 20px 'Lato';
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .estore-container {
        section.maincontent {
            .relevantstuffs {
                flex: 0 0 60%;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .estore-container {
        section.maincontent {
            flex-direction: column;
            padding: 2rem;
            .relevantstuffs {
                width: 100%;
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .estore-container {
        section.maincontent {
            .relevantstuffs {
                .details {
                    .realdeals {
                        div {
                            display: flex;
                            justify-content: space-between;
                            > div:first-child {
                                flex-direction: column;
                                flex: 0 0 45%;
                                p {
                                    font: 600 20px 'Plus Jakarta Sans';
                                    color: #2D334A;
                                    margin-top: 2rem;
                                }
                                img {
                                    width: 150px;
                                    height: 123px;
                                    border-radius: 10px;
                                }
                            }
                            > div:not(:last-child) {
                                div {
                                    p {
                                      &:first-child, &:last-child {
                                        border: 1px solid #4F0B92;
                                        height: 3rem;
                                        width: 3rem;
                                        text-align: center;
                                        border-radius: 5px;
                                        cursor: pointer;
                                      }
                                    }
                                }
                            }
                            .price {
                                font: 600 25px 'Plus Jakarta Sans';
                                line-height: 30.24px;
                                color: #2D334A;
                                margin-top: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

