.estore-container {
  margin: 0 auto;
  background-color: white;
  // padding: 1rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    .hidden {
      display: none;
    }

    section.login_page_for_individual.current_screen {
      padding: 48px 0px 32px 0px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      div.acct-type {
        display: flex;
        align-items: center;
        height: 56px;
        p {
          font: 500 16px 'Plus jakarta sans';
          line-height: 20.15px;
          color: #272343;
        }
        div.personal {
          padding: 8px;
          border-top: 1px solid #423F5A;
          border-right: 1px solid #423F5A;
          border-radius: 1px 10px 0px 1px;
          text-align: right;
          width: 50%;
          cursor: pointer;
        }
        div.merchant {
          padding: 8px;
          border-bottom: 1px solid #423F5A;
          border-radius: 1px 1px 0px 0px;
          text-align: left;
          width: 50%;
          cursor: pointer;
        }
      }

      div.personal-acct-form {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 48px;
        div.welcome {
          display: flex;
          flex-direction: column;
          gap: 8px;
          h1 {
            font: 600 40px 'plus jakarta sans';
            line-height: 50.14px;
            color: #272343;
            text-align: center;
            margin: unset;
          }
          p {
            font: 500 18px 'plus jakarta sans';
            line-height: 26px;
            color: #272343;
            text-align: center;
          }
        }
        form {
          display: flex;
          flex-direction: column;
          width: 544px;
          gap: 48px;
          div.requirements {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            div {
              p {
                font: 500 14px 'plus jakarta sans';
                line-height: 30px;
                span {
                  font: 500 14px 'plus jarkata sans';
                  color: red;
                }
              }
              input, select, textarea {
                padding: .375rem .75rem;
                font: 600 16px 'lato';
                width: 100%;
                line-height: 19px;
                height: 48px;
                color: #423F5A;
                background-color: #fff;
                background-clip: padding-box;
                border: 2px solid #d2d2d2;
                border-radius: 8px;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
              }
              input:focus, select:focus, textarea:focus {
                  border: 1px solid #007bff;
                  box-shadow: 0 0 0 .3rem rgba(0,123,255,.25);
              }
            }
          }
          div.other-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            width: 544px;
            button {
              width: 100%;
              padding: 8px;
              background-color: #423F5A;
              color: #fff;
              font: 700 16px 'plus jarkata sans';
              line-height: 30px;
              border-radius: 10px;
            }
            p  {
              font: 500 18px 'lato';
              line-height: 26px;
              color: #8B8B8B;
              a {
                font: 700 18px 'lato';
                color: #423F5A;
              }
            }
          }
        }
      }

      div.merchant-acct-form {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 48px;
        div.welcome {
          display: flex;
          flex-direction: column;
          gap: 8px;
          h1 {
            font: 600 40px 'plus jakarta sans';
            line-height: 50.14px;
            color: #272343;
            text-align: center;
            margin: unset;
          }
          p {
            font: 500 18px 'plus jakarta sans';
            line-height: 26px;
            color: #272343;
            text-align: center;
          }
        }
        form {
          display: flex;
          flex-direction: column;
          width: 544px;
          gap: 48px;
          div.requirements {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            div {
              p {
                font: 500 14px 'plus jakarta sans';
                line-height: 30px;
                span {
                  font: 500 14px 'plus jarkata sans';
                  color: red;
                }
              }
              input, select, textarea {
                padding: .375rem .75rem;
                font: 600 16px 'lato';
                width: 100%;
                line-height: 19px;
                height: 48px;
                color: #423F5A;
                background-color: #fff;
                background-clip: padding-box;
                border: 2px solid #d2d2d2;
                border-radius: 8px;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
              }
              input:focus, select:focus, textarea:focus {
                  border: 1px solid #007bff;
                  box-shadow: 0 0 0 .3rem rgba(0,123,255,.25);
              }
            }
          }
          div.other-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            width: 544px;
            button {
              width: 100%;
              padding: 8px;
              background-color: #423F5A;
              color: #fff;
              font: 700 16px 'plus jarkata sans';
              line-height: 30px;
              border-radius: 10px;
            }
            p  {
              font: 500 18px 'lato';
              line-height: 26px;
              color: #8B8B8B;
              a {
                font: 700 18px 'lato';
                color: #423F5A;
              }
            }
          }
        }
      }
    }

    
    section.login_page_for_merchant, section.login_page_for_individual {
      display: none;
    }

    section.login_page_for_merchant.current_screen {
      padding: 48px 0px 32px 0px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      div.acct-type {
        display: flex;
        align-items: center;
        height: 56px;
        p {
          font: 500 16px 'Plus jakarta sans';
          line-height: 20.15px;
          color: #272343;
        }
        div.personal {
          padding: 8px;
          border-bottom: 1px solid #423F5A;
          border-radius: 1px 10px 0px 1px;
          text-align: right;
          width: 50%;
          cursor: pointer;
        }
        div.merchant {
          padding: 8px;
          border-top: 1px solid #423F5A;
          border-radius: 10px 0px 0px 0px;
          border-left: 1px solid #423F5A;
          text-align: left;
          width: 50%;
          cursor: pointer;
        }
      }

      div.personal-acct-form {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 48px;
        div.welcome {
          display: flex;
          flex-direction: column;
          gap: 8px;
          h1 {
            font: 600 40px 'plus jakarta sans';
            line-height: 50.14px;
            color: #272343;
            text-align: center;
            margin: unset;
          }
          p {
            font: 500 18px 'plus jakarta sans';
            line-height: 26px;
            color: #272343;
            text-align: center;
          }
        }
        form {
          display: flex;
          flex-direction: column;
          width: 544px;
          gap: 48px;
          div.requirements {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            div {
              p {
                font: 500 14px 'plus jakarta sans';
                line-height: 30px;
                span {
                  font: 500 14px 'plus jarkata sans';
                  color: red;
                }
              }
              input, select, textarea {
                padding: .375rem .75rem;
                font: 600 16px 'lato';
                width: 100%;
                line-height: 19px;
                height: 48px;
                color: #423F5A;
                background-color: #fff;
                background-clip: padding-box;
                border: 2px solid #d2d2d2;
                border-radius: 8px;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
              }
              input:focus, select:focus, textarea:focus {
                  border: 1px solid #007bff;
                  box-shadow: 0 0 0 .3rem rgba(0,123,255,.25);
              }
            }
          }
          div.other-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            width: 544px;
            button {
              width: 100%;
              padding: 8px;
              background-color: #423F5A;
              color: #fff;
              font: 700 16px 'plus jarkata sans';
              line-height: 30px;
              border-radius: 10px;
            }
            p  {
              font: 500 18px 'lato';
              line-height: 26px;
              color: #8B8B8B;
              a {
                font: 700 18px 'lato';
                color: #423F5A;
              }
            }
          }
        }
      }

      div.merchant-acct-form, div.personal-acct-form {
        display: none;
      }

      div.merchant-acct-form {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 48px;
        div.welcome {
          display: flex;
          flex-direction: column;
          gap: 8px;
          h1 {
            font: 600 40px 'plus jakarta sans';
            line-height: 50.14px;
            color: #272343;
            text-align: center;
            margin: unset;
          }
          p {
            font: 500 18px 'plus jakarta sans';
            line-height: 26px;
            color: #272343;
            text-align: center;
          }
        }
        form {
          display: flex;
          flex-direction: column;
          width: 544px;
          gap: 48px;
          div.requirements {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            div {
              p {
                font: 500 14px 'plus jakarta sans';
                line-height: 30px;
                span {
                  font: 500 14px 'plus jarkata sans';
                  color: red;
                }
              }
              input, select, textarea {
                padding: .375rem .75rem;
                font: 600 16px 'lato';
                width: 100%;
                line-height: 19px;
                height: 48px;
                color: #423F5A;
                background-color: #fff;
                background-clip: padding-box;
                border: 2px solid #d2d2d2;
                border-radius: 8px;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
              }
              input:focus, select:focus, textarea:focus {
                  border: 1px solid #007bff;
                  box-shadow: 0 0 0 .3rem rgba(0,123,255,.25);
              }
            }
          }
          div.other-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            width: 544px;
            button {
              width: 100%;
              padding: 8px;
              background-color: #423F5A;
              color: #fff;
              font: 700 16px 'plus jarkata sans';
              line-height: 30px;
              border-radius: 10px;
            }
            p  {
              font: 500 18px 'lato';
              line-height: 26px;
              color: #8B8B8B;
              a {
                font: 700 18px 'lato';
                color: #423F5A;
              }
            }
          }
        }
      }
    }
  
  }
  
  
@media screen and (max-width: 1023px) {
  .estore-container {
    section.login_page_for_merchant, section.login_page_for_individual {
      padding: 48px 0px 32px 0px;
    }
  }
}

@media screen and (max-width: 600px) {
    .estore-container {
      max-width: 1200px;
  
      section.login_page_for_individual.current_screen {
        padding: 25px 0px 20px 0px;
        div.personal-acct-form {
          padding: 2rem;
          form {
            width: 100%;
            div.other-details {
              width: 100%;
            }
          }
        }
      }

      section.login_page_for_merchant.current_screen {
        padding: 25px 0px 20px 0px;
        div.merchant-acct-form {
          padding: 2rem;
          form {
            width: 100%;
            div.other-details {
              width: 100%;
            }
          }
        }
      }
    }
}


@media screen and (min-width: 1218px) {
  .estore-container {
    section.login_page_for_merchant, section.login_page_for_individual {
      padding: 6rem 0rem 5.6rem 0rem !important;
    }
  }
}