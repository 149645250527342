.estore-container {
    // padding: 4rem 1.6rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    span {
        color: #A0A2A7;
    }

    section.navs {
        padding: 1rem 5.6rem;
        display: flex;
        gap: 1rem;
        p {
            font: 500 20px 'Plus jakarta Sans';
            line-height: 25.2px;
            color: #A0A2A7;
        } .secondp {
            color: #2D334A;
        }
    }

    section.checkoutsec {
        padding: 3rem 5.6rem;
        display: flex;
        gap: 2rem;
        .shouldbeeditable {
            flex: 0 0 55%;
            h3 {
                font: 500 32px 'Plus jakarta Sans';
                line-height: 40.32px;
                color: #2D334A;
                margin-bottom: 1.5rem;
            }
            .thedetails {
                display: flex;
                gap: 1.5rem;
                flex-direction: column;
                padding: 2.5rem 1.5rem;
                border: 2px solid #EAEAEA;
                border-radius: 1.2rem;
                margin-bottom: 1rem;
                h4 {
                    text-transform: uppercase;
                    font-size: 14px;
                    color: #A0A2A7;
                }
                .descrip {
                    p {
                        font: 500 16px 'Plus jakarta Sans';
                        line-height: 20.16px;
                        color: #5B5D64;
                        margin-bottom: 1rem;
                        span {
                            color: red;
                        }
                    }
                    input, select {
                        width: 100%;
                        border-radius: 1rem;
                        height: 5rem;
                        background: #f5f5f5;
                        padding: 0 1.5rem;
                        font: 500 16px 'Plus jakarta Sans';
                        color: #2D334A;
                    }
                }
            }
        }
        .sideconts {
            background-color: #f5f5f5;
            height: 100%;
            width: 100%;
            gap: 2rem;
            padding: 2.5rem;
            border-radius: 2.5rem;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            > p:first-child {
                color: #A0A2A7;
                font: 600 15px 'Plus Jakarta Sans';
                line-height: 18px;
                text-transform: uppercase;
            }
            .desc {
                display: flex;
                justify-content: space-between;
                p {
                    font: 600 20px 'Plus Jakarta Sans';
                    line-height: 25.2px;
                    color: #2D334A;
                }
            }
            .grped {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                > div { 
                    display: flex; 
                    justify-content: space-between;
                    align-items: center;
                    > p:first-child {
                        font: 500 20px 'Plus Jakarta Sans';
                        line-height: 25.2px;
                        color: #A0A2A7;
                    }
                    > p:last-child {
                        font: 600 20px 'Plus Jakarta Sans';
                        line-height: 25.2px;
                        color: #2D334A;
                    }
                }
                .items {
                    justify-content: space-between;
                    p {
                        font: 600 20px 'Plus Jakarta Sans';
                        line-height: 25.2px;
                        color: #2D334A;
                    } 
                    > p:not(:first-child :last-child) {
                        margin-left: 1rem;
                    }
                }
                hr {
                    border: 1px solid #2D334A;
                }
                .table-container {
                    width: 100%;
                    overflow-x: auto;
                
                .custom-table {
                    width: 100%;
                    border-collapse: collapse;
                        th {
                            background-color: #f2f2f2;
                            font-weight: bold;
                        }
                }
                
                .custom-table th, .custom-table td {
                    padding: 5px;
                    border: 1px solid #ddd;
                }
                
                
                .custom-table tbody tr:nth-child(even) {
                    background-color: #f2f2f2;
                }

                }
            }
            button {
                background-color: #4F0B92;
                border-radius: 1rem;
                padding: 2rem;
                margin-bottom: 2rem;
                p {
                    font: 500 20px 'Lato';
                    color: #fff;
                }
            }
        }
    }
}


@media screen and (max-width: 1300px) {
    .estore-container {
        section.checkoutsec {
            width: 100%;
            justify-content: space-between;
            .shouldbeeditable {
                flex: 0 0 40%;
            }
            .sideconts {
                flex: 0 0 60%;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .estore-container {
        section.checkoutsec {
            .shouldbeeditable {
                flex: 0 0 40%;
            }
            .sideconts {
                width: 60%;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .estore-container {
        section.checkoutsec {
            flex-direction: column;
            .sideconts {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .estore-container {
        section.checkoutsec {
            padding: 2rem;
        }
    }
}

@media screen and (max-width: 600px) {
    .estore-container {
        section.checkoutsec {
            flex-direction: column;
        }
    }
}

@media screen and (min-width: 1218px) {
    .estore-container section.navs {
        padding-top: 6.5rem;
        padding-left: 5.6rem;
        padding-right: 5.6rem;
    }
}