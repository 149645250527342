footer {
  display: flex;
  flex-direction: column;
  padding: 94px 54px;
  background-color: #000;
  color: #fff;
  div.logoSection {
    width: 244px;
    height: 85px;
  }
  div.allFooterDetails {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
    font: 700 18px 'Plus Jakarta Sans';
    .singleFooterItems {
      ul {
        gap: 32px;
        display: flex;
        flex-direction: column;
        li {
            a {
              font: 500 16px 'Lato';
              img {
                width: 21px;
              }
            }
            p {
              font: 500 16px 'Lato';
              cursor: not-allowed;
              img {
                width: 21px;
              }
            }
        }
      }
      h5 {
        margin-bottom: 30px;
      }
    }
    .singleFooterItemsForProducts {
      grid-column: span 2;
      grid-column-end: span 1;
      flex: 0 0 24%;
      h5 {
        text-align: center;
        margin-bottom: 30px;
      }
      div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        ul {
          gap: 32px;
          display: flex;
          flex-direction: column;
          li {
            a {
              font: 500 16px 'Lato';
              img {
                width: 21px;
              }
            }
            p {
              font: 500 16px 'Lato';
              cursor: not-allowed;
              img {
                width: 21px;
              }
            }
        }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  footer {
    div.allFooterDetails {
        grid-template-columns: repeat(5, 1fr);
    }
  }
}

@media screen and (max-width: 900px) {
  footer {
    div.allFooterDetails {
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
    }
  }
}

@media screen and (max-width: 700px) {
  footer {
    padding: 35px;
    div.allFooterDetails {
        grid-template-columns: repeat(2, 1fr);
        .singleFooterItemsForProducts {
          grid-column: 1;
          h5 {
            text-align: left;
          }
          div {
            ul {
              gap: 15px;
            }
          }
        }
        .singleFooterItems {
          ul {
            gap: 15px;
          }
        }
    }
  }
}

@media screen and (max-width: 500px) {
  footer {
    div.allFooterDetails {
        grid-template-columns: repeat(1, 1fr);
    }
  }
}

