.estore-container {
    // padding: 4rem 1.6rem;
    font: 400 18px 'Plus Jakarta Sans';
    
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }

    section.askquestions {
        padding: 5rem 8rem;
        background-color: #d4d3d3;
        .big-title {
            padding: 15px;
            h2 {
                font-size: 4rem;
                font-weight: 300;
                line-height: 1.2;
                text-align: center;
                text-transform: capitalize;
                color: #272343;
                margin-bottom: 0.8rem;
            }
        }
        .before-the-row {
            background-color: #fff;
            .row {
                display: flex;
                flex-direction: column;
                padding: .75rem 2rem;
                background-color: rgba(0, 0, 0, .03);
                border-bottom: 1px solid rgba(0, 0, 0, .125);
                .home {
                    padding: 1.5rem;
                    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
                    width: 100%;
                    button {
                        background-color: #4F0B92;
                        color: #e4e2e2;
                        padding: 1.3rem;
                        width: 100%;
                    }
                }
                .real-section {
                    display: flex;
                    flex-direction: column;
                    gap: 3rem;
                    .welcome {
                        margin-top: 3rem;
                    }
                    .form-starts {
                        width: 100%;
                        form { 
                            display: flex;
                            flex-direction: column;
                            gap: 1.5rem;
                            div {
                                display: flex;
                                flex-direction: column;
                                gap: 0.5rem;
                                p {
                                    font-size: 1.5rem;
                                    span {
                                        color: red;
                                    }
                                }
                            }
                            .name-and-email {
                                display: flex;
                                justify-content: space-between;
                                flex-direction: row;
                                div {
                                    flex: 0 0 50%;
                                    p {
                                        font-size: 1.5rem;
                                        span {
                                            color: red;
                                        }
                                    }
                                }
                            }
                            button {
                                background-color: #4F0B92;
                                color: #e4e2e2;
                                padding: 1.3rem;
                                width: 100%;
                                border-radius: .25rem;
                            }
                        }
                    }
                }
                
            }
        }

        input, select, textarea {
            padding: .375rem .75rem;
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }
        input:focus, select:focus, textarea:focus {
            border: 1px solid #007bff;
            box-shadow: 0 0 0 .3rem rgba(0,123,255,.25);
        }
    }
}


@media screen and (max-width: 700px) {
    .estore-container { 
        section.askquestions {
            padding: 3rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .estore-container { 
        section.askquestions {
            padding: 2rem;
            .before-the-row .row {
                .home {
                    padding: .5rem;
                }
                .real-section {
                    gap: 2rem;
                    .form-starts form {
                        .name-and-email {
                            flex-direction: column;
                            gap: 2rem;
                        }
                    }
                }
            }
        }
    }
}

