.estore-container {
    // padding: 4rem 1.6rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    span {
        color: #A0A2A7;
    }

    section.wishlist {
        padding: 0rem 1rem;
        display: flex;
        gap: 30px;
        width: 100%;
        .relevantstuffs {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-right: 1rem;
            .titlesandall {
                display: flex;
                justify-content: space-between;
                margin-bottom: 2rem;
                h1 {
                    font: 700 30px 'circular std';
                    color: #05004E;
                }
            }
            .details {
                display: flex;
                flex-direction: column;
                padding: 2rem 2rem;
                border: 1px solid #EAEAEA;
                // box-shadow: 5px 10px 20px 10px #EEEEEE;
                border-radius: 15px;
                width: 100%;
                gap: 1.5rem;
                .detailstitle {
                    display: flex;
                    justify-content: space-between;
                    padding: 0rem 1rem;
                    p {
                        &:first-child {
                            flex: 0 0 35%;
                        }
                        font: 600 15px 'Plus Jakarta Sans';
                        line-height: 18px;
                        color: #A0A2A7;
                        text-transform: uppercase;
                    }
                }
                .realdeals {
                    div {
                        display: flex;
                        justify-content: space-between;
                        > div:first-child {
                            flex: 0 0 45%;
                            p {
                                font: 600 20px 'Plus Jakarta Sans';
                                color: #2D334A;
                                margin-top: 2rem;
                            }
                            img {
                                width: 150px;
                                height: 123px;
                                border-radius: 10px;
                            }
                        }
                        > div:not(:last-child) {
                            div {
                                p {
                                  &:first-child, &:last-child {
                                    border: 1px solid #4F0B92;
                                    height: 3rem;
                                    width: 3rem;
                                    text-align: center;
                                    border-radius: 5px;
                                    cursor: pointer;
                                  }
                                }
                            }
                        }
                        .price {
                            font: 600 25px 'Plus Jakarta Sans';
                            line-height: 30.24px;
                            color: #2D334A;
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1218px) {
    .estore-container section.wishlist {
        padding: 8.6rem 0.5rem;
    }
}

@media screen and (max-width: 1200px) {
    .estore-container {
        section.wishlist {
            div.left-side {
                flex: 0 0 260px;
                padding: 0px 2px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .estore-container {
        section.wishlist {
            padding: 3rem;
            flex-direction: column;
            div.left-side {
                display: none;
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .estore-container {
        section.wishlist {
            padding: 5rem 1.5rem;
            .relevantstuffs {
                .details {
                    .realdeals {
                        div {
                            display: flex;
                            justify-content: space-between;
                            > div:first-child {
                                flex-direction: column;
                                flex: 0 0 45%;
                                p {
                                    font: 600 20px 'Plus Jakarta Sans';
                                    color: #2D334A;
                                    margin-top: 2rem;
                                }
                                img {
                                    width: 150px;
                                    height: 123px;
                                    border-radius: 10px;
                                }
                            }
                            > div:not(:last-child) {
                                div {
                                    p {
                                      &:first-child, &:last-child {
                                        border: 1px solid #4F0B92;
                                        height: 3rem;
                                        width: 3rem;
                                        text-align: center;
                                        border-radius: 5px;
                                        cursor: pointer;
                                      }
                                    }
                                }
                            }
                            .price {
                                font: 600 25px 'Plus Jakarta Sans';
                                line-height: 30.24px;
                                color: #2D334A;
                                margin-top: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

