.mainEstore-header {
    // max-width: 1280px;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    background-color: #4F0B92;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
    gap: 1rem;
    & > ul {
      list-style-type: none;
      width: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0 0 35%;
      li {
        display: inline-block;
        position: relative;
        ul {
          display: none;
          &.active {
            max-height: 50rem;
            position: absolute;
            top: 30px;
            left: 0;
            z-index: 3;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(5, 1fr);
            width: 65rem;
            height: 38rem;
            border-radius: 10px;
            overflow: hidden;
            transition: max-height ease-in-out 0.5s;
            background-color: #4F0B92;
            gap: 0rem;
            padding: 3rem;
          }
          li {
            padding: 0.5rem 0.5rem;
            a {
              color: #fff;
            }
            &:first-child {
              margin-top: 0rem;
            }
            &:last-child {
              margin-bottom: 2rem;
            }
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
            :hover {
              text-decoration: underline;
              text-decoration-color: #fff;
            }
          }
        }
        a {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          color: #fff;
          position: relative;
          text-decoration: none;
          top: -5px;
          button {
            outline: none;
          }
          &.active {
            &::after {
              content: "";
              position: absolute;
              height: 3px;
              width: 120%;
              background-color: #fff;
              bottom: -15px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
    p {
      font-family: plus Jakarta  sans;
    }
    div.lastside {
      list-style-type: none;
      // width: 45%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        color: #fff;
        border-radius: 10px;
        
      }
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        color: #fff;
        a {
          text-decoration: underline;
        }        
      }
      div {
        padding: 1rem;
        border-radius: 1rem;
        border: 2px solid #EAEAEA;
        margin-left: 1rem;
        cursor: pointer;
      }
      div .account {
        display: none;
      }
      div .account.active {
        // max-height: 50rem;
        position: absolute;
        top: 9.8rem;
        left: 77%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        width: 30rem;
        // height: 25rem;
        border-radius: 10px;
        overflow: hidden;
        transition: max-height ease-in-out 0.5s;
        background-color: #4F0B92;
        gap: 2rem;
        padding: 3rem;
        > li:first-child, .guest-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: unset !important;
          a {
            width: 90%;
          }
        }
        > li:not(:first-child, :last-child) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 8.5rem;
        }
        > li:last-child {
          width: 40%;
        }
        
        link {
          font: 600 16px 'plus jakarta sans';
        }
      }
      .acctstuffs {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        
      }
    }
    
    div.searching {
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 3rem !important;
      svg {
        padding-right: 1rem;
        color: #fff;
      }
      input {
        background: #4F0B92;
        color: #fff;

      }
    }
  }
  
  nav.desktop-nav {
    display: none;
  }
  
  nav.mobile-nav {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 1.6rem;
    position: fixed;
    z-index: 3;
    width: 100%;
    top: 0;
  
    img {
      width: 100px;
    }
    .buttons {
      display: flex;
      align-items: center;
      a {
        background: #4F0B92;
        border-radius: 43px;
        border: none;
        color: #fff;
        font-weight: 800;
        font-size: 12px;
        line-height: 18px;
        padding: 0.8rem;
        margin-right: 2rem;
        width: 125px;
        text-align: center;
      }
      button {
        width: 18px;
        height: 18px;
        img {
          width: 18px;
          height: 18px;
        }
        background: transparent;
        border: none;
      }
    }
    div.menu {
      overflow-y: auto;
      position: absolute;
      padding: 1.6rem;
      padding-top: 4rem;
      padding-left: 3rem;
      top: 0;
      left: 0;
      background-color: #fff;
      width: 280px;
      height: 100vh;
      z-index: 3;
      transition: transform ease-in-out 0.5s;
      transform: translateX(-280px);
      &.active {
        transform: translateX(0);
      }
      & > button {
        position: absolute;
        right: 20px;
        top: 10px;
        background-color: transparent;
        font-size: 2.4rem;
        border: none;
      }
      ul {
        list-style-type: none;
        &.submenu {
          padding-left: 1.6rem;
          max-height: 0;
          overflow: hidden;
          transition: max-height ease-in-out 0.5s;
          &.active {
            max-height: 100%;
          }
        }
        li {
          margin-bottom: 3rem;
          display: block;
          a {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #272343;
            text-decoration: none;
          }
          button {
            background-color: transparent;
            border: none;
            margin-left: 1rem;
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 900px) {
    nav.mobile-nav {
      display: none;
    }
    nav.desktop-nav {
      display: block;
      padding: 0 0.5rem;
      position: fixed;
      z-index: 3;
      width: 100%;
      .mainEstore-header {
        img {
          width: 120px;
        }
        ul li a button {
          background-color: transparent;
          border: none;
        }
      }
    }
  }
  
  @media screen and (min-width: 1200px) {
    nav.desktop-nav {
      .mainEstore-header {
        img {
          width: unset;
        }
        ul li a button {
          margin-left: 1rem;
          display: inline-block;
        }
      }
    }
  }
  