.estore-container {
    // padding: 4rem 1.6rem;
    font: 500 20px 'Plus Jakarta Sans';
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }

    section.gallery {
        padding: 87px 82px;
        background-color: #f3f3f3;
        div.contents {
            padding: 50px;
            background-color: #fff;
            border-radius: 15px;
            display: flex;
            gap: 50px;
            flex-direction: column;
            h2 {
                font: 600 45px 'Plus jakarta sans';
                text-align: center;
                line-height: 28px;
                // text-transform: uppercase;
            }
            div.gridItems {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 48px;
                .galleryitems {
                    padding: 25px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    border: 1px solid;
                    border-radius: 15px;
                    img {
                        width: 334px;
                        height: 167px;
                        border-radius: 8px;
                    }
                    div {
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        h5 {
                            font: 600 18px 'lato';
                            height: 28px;
                            letter-spacing: 5px;
                        }
                        p {
                            font: 600 16px 'lato';
                            height: 28px;
                            letter-spacing: 5px;
                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                        & > p:last-child {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                        }
                    }
                    a {
                        font: 400 16px 'lato';
                        line-height: 28px;
                        letter-spacing: 5px;
                    }
                }
            }
        }
    }
  
}

@media screen and (max-width: 1300px) {
    .estore-container { 
        section.gallery {
            div.contents div.gridItems {
                div.galleryitems {
                    gap: 10px;
                    div {
                        h5 {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }    
    }
}


@media screen and (max-width: 1200px) {
    .estore-container { 
        section.gallery {
            padding: 35px 25px;
            div.contents {
                div.gridItems {
                    gap: 15px
                }
            }
        }    
    }
}

@media screen and (max-width: 1100px) {
    .estore-container { 
        section.gallery {
            padding: 35px 25px;
            div.contents {
                div.gridItems {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 50px
                }
            }
        }    
    }
}

@media screen and (max-width: 900px) {
    .estore-container { 
        section.gallery {
            // padding: 35px 25px;
            div.contents {
                div.gridItems {
                    // grid-template-columns: repeat(2, 1fr);
                    gap: 10px
                }
            }
        }    
    }
}

@media screen and (max-width: 800px) {
    .estore-container { 
        section.gallery {
            padding: 85px;
            div.contents {
                padding: 75px;
                div.gridItems {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 30px
                }
            }
        }    
    }
}

@media screen and (max-width: 700px) {
    .estore-container { 
        section.gallery {
            padding: 50px 20px;
            div.contents {
                padding: 30px;
                div.gridItems {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 30px;
                    .galleryitems {
                        img {
                            width: 100%;
                            height: 60%;
                        }
                    }
                }
            }
        }    
    }
}

@media screen and (max-width: 600px) {
    .estore-container { 
        section.gallery {
            padding: 20px;
            div.contents {
                h2 {
                    font: 600 35px 'Plus jakarta sans';
                    line-height: 5px;
                }
            }
        }    
    }
}

