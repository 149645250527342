.estore-container {
    // padding: 4rem 1.6rem;
    font: 500 20px 'Plus Jakarta Sans';
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }

    section.pricing {
        padding: 4rem 7rem;
        display: flex;
        flex-direction: column;
        gap: 85px;
        h2 {
            font: 600 45px 'Plus jakarta sans';
            text-align: center;
            line-height: 28px;
            padding-left: 33px;
            // text-transform: uppercase;
        }
        div.choose-country {
            background-color: rgba(0, 0, 0, 0.05);
            padding: 2rem 5rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            h5 {
                font-size: 2.2rem;
                font-weight: 700;
            }
            select {
                width: 100%;
                height: calc(1.88em + 1.5rem + 2px);
                padding: 0.75rem 1.5625rem;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 8px;
            }
        }
        div.acct-type {
            display: flex;
            align-items: center;
            height: 56px;
            margin-bottom: -4rem;
            margin-top: -5rem;
            p {
              font: 500 16px 'Plus jakarta sans';
              line-height: 20.15px;
              color: #272343;
            }
        }
        div.acct-type2 {
            display: flex;
            align-items: center;
            height: 56px;
            margin-bottom: -4rem;
            margin-top: -5rem;
            p {
              font: 500 16px 'Plus jakarta sans';
              line-height: 20.15px;
              color: #272343;
            }
        }
        #seller, #acct-type2 {
            display: none;
        }
        div.plans {
            display: grid !important;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
            .singleItems {
                font: 700 25px 'Plus jakarta sans';
                line-height: 28px;
                border-radius: 20px;
                border: 1px solid #F8F9FA;
                box-shadow: 5px 10px 10px 10px #EEEEEE;
                h4 {
                    padding: 33px 35px;
                    border-bottom: 2px solid #4F0B92;
                    display: flex;
                    justify-content: space-between;
                }
                .describePlan {
                    display: flex;
                    flex-direction: column;
                    padding: 33px 35px;
                    gap: 13px;
                    .title {
                        font: 400 16px 'Lato';
                        line-height: 28px;
                        // text-align: center;
                    }
                    p {
                        font: 400 20px 'Lato';
                        line-height: 28px;
                    }
                    em {
                        font: 400 15px 'Lato';
                        color: red;
                    }
                    .detailedplans {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        div  {
                            gap: 19px;
                            display: flex;
                            flex-direction: column;
                            p {
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                                svg {
                                    color: green;
                                }
                            }
                        }
                        button {
                            background-color: #4F0B92;
                            padding: 8px;
                            border-radius: 5px;
                            color: #fff;
                            font: 600 14px 'Plus jakarta sans';
                            height: 40px;
                            margin-top: 2rem;
                        }
                        
                    }
                }
                &.singleItems:nth-child(2) {
                    background-color: #4F0B92;
                    color: #fff;
                    h4 {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 2px solid #fff;
                    }
                    button {
                        background-color: #9747FF;
                    }
                }
            }
        }
    }
  
}

@media screen and (max-width: 1300px) {
    .estore-container { 
        section.pricing {
            div.plans {
                gap: 25px;
            }
        }    
    }
}

@media screen and (max-width: 1023px) {
    .estore-container { 
        section.pricing {
            div.plans {
                // display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 25px;
            }
        }    
    }
}

@media screen and (max-width: 800px) {
    .estore-container { 
        section.pricing {
            div.plans {
                // display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 60px;
            }
        }    
    }
}

@media screen and (max-width: 600px) {
    .estore-container { 
        section.pricing {
            padding: 4rem 3rem;
            div.choose-country {
                padding: 2rem;
            }
        }    
    }
}


@media screen and (min-width: 1218px) {
    .estore-container {
        section.pricing {
            padding: 8.6rem;
        }
    }
}