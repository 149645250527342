.estore-container {
    // padding: 4rem 1.6rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }
    span {
        color: #A0A2A7;
    }

    section.ratings {
        padding: 5rem 30rem;
        form {
          display: flex;
          gap: 40px;
          flex-direction: column;
          .head {
            display: flex;
            flex-direction: column;
            gap: 2px;
            align-items: center;
            h1 {
              font-family: sans-serif;
              font-weight: 600;
              font-size: 20px;
              margin: unset;
            }
            div {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: center;
              svg {
                width: 14px;
                height: 14px;
                color: #E59819;
              }
            }
          }
          .contents {
            display: flex;
            flex-direction: column;
            gap: 30px;
            div {
              display: flex;
              flex-direction: column;
              gap: 8px;
              h5 {
                font-size: 16px;
                font-weight: 500;
                line-height: 20.16px;
                color: #1D2939;
              }
              input {
                border: 1px solid #DDDDDD;
                border-radius: 15px;
                padding: 15px 22px;
              }
              textarea {
                border: 1px solid #DDDDDD;
                border-radius: 15px;
                padding: 15px 22px;
                height: 250px;
              }
            }

          }
          button {
            padding: 16px 28px;
            background-color: #3A4980;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            border-radius: 10px;
            color: #fff;
            align-self: center;
          }
          button.loader { align-self: unset;}
        }
    }
}


@media screen and (max-width: 1200px) {
  .estore-container {
    section.ratings {
      padding: 2rem 10rem;
    }
  }
}


@media screen and (max-width: 900px) {
  .estore-container {
    section.ratings {
      padding: 3rem 5rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .estore-container {
    section.ratings {
      padding: 3rem 2rem;
    }
  }
}