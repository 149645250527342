.estore-container {
    // padding: 4rem 1.6rem;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 3.2rem;
    }

    img.with-no-bg {
        width: 1.5rem;
    }

    section.oneproduct {
        padding: 5rem 5.6rem;
        display: flex;
        .imagessection {
            gap: 2rem;
            display: flex;
            flex-direction: column;
            .themainimage {
                img {
                    width: 65rem;
                    height: 55rem;
                    border-radius: 8px;
                }
            }
            .otherimages {
                display: grid;
                gap: 1rem;  
                grid-template-columns: repeat(4, 1fr);  
                img{ 
                    width: 13rem;
                    height: 9rem;
                    border-radius: 10px;
                    border: 1px solid #4F0B92;
                    cursor: pointer;
                }
            }
        }
        .describingtheimages {
            flex: 0 0 50%;
            padding: 0rem 2rem;
            display: flex;
            flex-direction: column;
            gap: 20px;
            h2 {
                font: 600 32px 'Plus Jakarta Sans';
                line-height: 40px;
                color: #2d334a;
                text-align: left;
            }
            .longpiece {
                display: flex;
                flex-direction: column;
                gap: 16px;
                h4 {
                    font: 600 20px 'Plus Jakarta Sans';
                    line-height: 25.2px;
                    color: #000000;
                } p {
                    color: #2d334a;
                    font: 500 16px 'Plus Jakarta Sans';
                    line-height: 22.4px;
                }
                div.dynamic {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    div {
                        display: flex;
                        flex-direction: column;
                        gap: 9px;
                        .choose {
                            font: 600 16px 'Plus Jakarta Sans';
                            line-height: 20.2px;
                            color: #B9BBBF;
                        }
                        .checkbtns {
                            display: grid;
                            gap: 8px;
                            grid-template-columns: repeat(5, 1fr);
                            width: 80%;
                            button {
                                color: #fff;
                                padding: 10px 33px;
                                border-radius: 5px;
                            }
                            button.first {
                                background-color: #A30037;
                            }
                            button.second {
                                background-color: #012329;
                            }
                            button.third {
                                background-color: #BBC1F8;
                            }
                            button.fourth {
                                background-color: #FFD3F8;
                            }
                            button.fifth {
                                background: linear-gradient(180deg, #A30037 50%, #14272B 50%);
                            }
                        }
                        .checkinputs {
                            display: grid;
                            gap: 8px;
                            grid-template-columns: repeat(5, 1fr);
                            width: 80%;
                            p {
                                display: flex;
                                align-items: center;
                                font: 500 16px 'plus jakarta sans';
                                padding: 8px;
                                flex-direction: row;
                                gap: 5px;
                                background-color: #E6E6E6;
                                border-radius: 7px;
                                &.active {
                                    color: #3A4980;
                                }
                                input {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                        .quantity {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 15%;
                            padding: 8px;
                            flex-direction: row;
                            gap: 5px;
                            background-color: #E6E6E6;
                            border-radius: 7px;
                            p {
                                font: 500 12px 'Plus Jakarta Sans';
                            }
                        }
                    }
                }
            }
            .ratings {
                display: flex;
                gap: 1rem;
                span {
                    display: flex;
                    gap: 0.2rem;
                    img {
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }
            
            .storedealer {
                font: 600 20px 'Plus Jakarta Sans';
                line-height: 25.2px;
                color: #A0A2A7;
                span {
                    color: #2D334A;
                }
            }
            .amount {
                font: 600 32px 'Plus Jakarta Sans';
                line-height: 40px;
                color: #2d334a;
            }
            form.justbuttons {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                button {
                    padding: 23.5px 88.5px;
                    border-radius: 10px;
                    &:first-child {
                        color: #2D334A;
                        background-color: #E6E6E6;
                    }
                    &:last-child {
                        color: #fff;
                        background-color: #402DA3;
                    }
                }
            }
        }
    }

    section.similarproducts {
        padding: 5rem 5.6rem;
        // margin-bottom: 3rem;
        .producttitle {
            margin-bottom: 10px;
            p {
              text-align: center;
              text-transform: uppercase;
              font: 600 24px 'Plus Jakarta Sans';
              line-height: 30.24px;
              color: #5B5D64;
            }
        }
        .items {
          display: flex;
          gap: 3rem;
          overflow-x: scroll;
          scroll-behavior: smooth;
          scrollbar-width: none; // This line hides the scroll bar for firefox browser only
        //   display: grid;
        //   grid-template-columns: repeat(4, 1fr);
          //grid-template-rows: repeat(1, 1fr);
          .eachItem {
            flex: 0 0 auto;
            width: 306px;
            .fortheimages {
                width: 306px;
                height: 240px;
                border-radius: 8px;
            }
            .imgdescription {
              margin: 4% auto;
              .nameofitem {
                font-weight: 500;
                font-size: 20px;
                line-height: 25.2px;
              }
              .priceofitem {
                font-weight: 600;
                font-size: 18px;
              }
              .initialprice {
                font-weight: 500;
                font-size: 14.5px;
                color: #C6C6C6;
              }
              div {
                display: flex;
                margin: 1rem auto;
                span {
                  display: grid;
                  grid-template-columns: repeat(5, 1fr);
                  column-gap: 0.5rem;
                }
                p {
                  margin-left: 1rem;
                }
              }
            }
          }
        }

        .items::-webkit-scrollbar {
            display: none; // This line hides the scroll bar for Chrome, Safari and Opera browsers
        }

        .scroll-effects {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            bottom: 23rem;
            padding: 0rem 2rem;
            button {
                background-color: #fff;
                padding: 12.25px 18.37px;
                border-radius: 30px;
            }
        }
    }

    section.feedbacks {
        padding: 5rem 5.6rem;
        display: flex;
        gap: 10px;
        div.feeds {
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            background-color: #F9FAFB;
            flex: 0 0 30%;
            h5 {
                padding: 14px;
                font: 600 24px 'Plus Jakarta Sans';
                line-height: 30.24px;
                color: #344054;
            }
            .all {
                padding: 35px;
                align-items: center;
                display: flex;
                flex-direction: column;
                gap: 10px;
                h3 {
                    font: 800 60px 'Plus Jakarta Sans';
                    line-height: 75.6px;
                    color: #164C96;
                }
                div {
                    // display: grid;
                    // grid-template-columns: repeat(5, 1fr);
                    // column-gap: 10px;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
                p {
                    font: 400 16px 'Plus Jakarta Sans';
                    line-height: 26px;
                    color: #4F547B;
                }
            }
        }

        div.detailed-ratings {
            display: flex;
            flex-direction: column;
            gap: 20px;
            border-radius: 8px;
            background-color: #F9FAFB;
            padding: 15px 30px;
            flex: 0 0 50%;
            width: 100%;
            .together {
                display: flex;
                align-items: center;
                justify-content: space-between;
                div.progress {
                    flex: 0 0 65%;
                }
                div.stars {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    column-gap: 10px;
                    img.with-no-bg {
                        width: 94%;
                    }
                }
                p {
                    font: 400 16px 'Plus Jakarta Sans';
                    line-height: 30px;
                    color: #164C96;
                }
            }
        }
    }

    section.reviews {
        padding: 3rem 5.6rem;
        display: flex;
        flex-direction: column;
        gap: 23px;
        h5 {
            font: 600 24px 'Plus Jakarta Sans';
            line-height: 30.24px;
            color: #1D2939;
        }
        div.contents {
            display: flex;
            flex-direction: column;
            gap: 30px;
            .each {
                display: flex;
                flex-direction: row;
                gap: 24.43px;
                img {
                    width: 51.13px;
                    height: 51.13px;
                    border-radius: 50%;
                }
                div.combine {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .head {
                        h6 {
                            font: 550 17px 'Plus Jakarta Sans';
                            line-height: 26px;
                            color: #1D2939;
                            span {
                                font: 400 13px 'Plus Jakarta Sans';
                                line-height: 16.4px;
                                color: #4F547B;
                                padding-left: 7px;
                            }
                        }
                        .ratings {
                            display: flex;
                            gap: 5px;
                            padding-top: 5px;
                            img {
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                    .body {
                        h6 {
                            font: 400 15px 'Plus Jakarta Sans';
                            line-height: 19px;
                            color: #1D2939;
                        }
                        p {
                            font: 400 15px 'Plus Jakarta Sans';
                            line-height: 26px;
                            color: #667085;
                        }
                    }
                }
            }
            hr {
                border: 1px solid #EDEDED;
            }
        }
    }
  
    .view-all-reviews {
        font: 400 16px 'Plus Jakarta Sans';
        line-height: 20.16px;
        color: #E7AA07;
        text-align: center;
        text-decoration: underline;
        padding-top: 2rem;
    }

}



@media screen and (max-width: 1200px) {
    .estore-container {
        section.oneproduct { 
            max-width: 1200px;
            justify-content: center;
        }

    }
}

@media screen and (max-width: 900px) {
    .estore-container {
        section.oneproduct { 
            flex-direction: column;
            gap: 3rem;
            align-items: center;
        }

        section.feedbacks {
            div.feeds {
                flex: 0 0 50%;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .estore-container {
        section.oneproduct, section.similarproducts, section.feedbacks, section.reviews {
            padding: 2rem;
        }

        section.oneproduct {
            .describingtheimages {
                .longpiece {
                    div.dynamic {
                        div {
                            .quantity {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 700px) {
    .estore-container {
        section.oneproduct {
            .describingtheimages {
                form.justbuttons {
                    justify-content: space-evenly;
                    button {
                        padding: 20px 60px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .estore-container {
        section.oneproduct { 
            padding: 2.5rem;
            .imagessection {
                .themainimage {
                    img {
                        width: 100%;
                        height: unset;
                    }
                }
                .otherimages {
                    img {
                        width: 9rem;
                        height: 7rem;
                    }
                }
            }
            .describingtheimages {
                width: 100%;
                h2 {
                    font: 600 25px 'Plus Jakarta Sans';
                    line-height: 40.4px;
                    text-align: left; 
                }
                form.justbuttons {
                    justify-content: space-between;
                    button {
                        padding: 15px 30px;
                    }
                }
                .longpiece {
                    div.dynamic {
                        div {
                            .checkinputs {
                                grid-template-columns: repeat(3, 1fr);
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        section.similarproducts {
            padding: 2rem;
            .items {
                a { 
                    .eachItem {
                        .fortheimages {
                            width: 100%;
                            // height: unset;
                            height: 22rem;
                        }
                        .imgdescription {
                            margin: 4% auto;
                            .nameofitem {
                              font-size: 18px;
                            }
                            .priceofitem {
                              font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        section.feedbacks {
            div.feeds {
                flex: 0 0 40%;
                h5 {
                    padding: 4px 14px;
                }
                .all {
                    padding: 0px 10px;
                }
            }
            div.detailed-ratings {
                flex: unset;
                padding: 10px 15px;
            }
        }
    }
}

